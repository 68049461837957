import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import Analytics from '../components/Analytics';
import Intro from '../components/Intro';
import Layout from "../components/Layout";
import { Link } from 'gatsby';
import { ROUTES } from './index';
import React from "react";
import SEO from "../components/Meta";

function NotFoundPage() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({id: "title_404", defaultMessage: "Deze pagina kon niet gevonden worden."})}
        description={intl.formatMessage({
          id: "description_404",
          defaultMessage: "Deze pagina bestaat niet."})}
        keywords={[`dww`, `de warmste week`, `het warmste koor`, `404`]}
      />

      <Analytics
        title="404"
      />

      <Intro title={intl.formatMessage({id: "404_title", defaultMessage: "Deze pagina kon niet gevonden worden."})} showTitle={true}>
        <FormattedMessage
          id="404_content"
          defaultMessage=" De opgevraagde pagina kon niet gevonden worden." />

        <p>
          <Link to={ROUTES.HOME}>
            <FormattedMessage
              id="404_home"
              defaultMessage="Ga naar de homepagina." />
          </Link>
        </p>
      </Intro>

    </Layout>
  );
}

export default NotFoundPage;
